.inside-container   
    margin-left: 0
    margin-right: 0
    padding-left: 0
    padding-right: 0
    .col-md-3
        padding-right: 0

// prgoress bar
    
.ant-progress-outer
.ant-progress-inner
    background: #c1b3bf
    height: 23px
.ant-progress-bg
    background: #84687f
    height: 100% !important

.ant-slider-rail
    background: #c1b3bf
    height: 18px
    border-radius: 25px
.ant-slider-step
    height: 18px
    border-radius: 25px
.ant-slider-handle
    width: 25px
    height: 26px
.ant-slider-track
    height: 18px
    border-radius: 25px
    background-color: #84687f
    border-color:#84687f
    color:#84687f
    
.ant-slider:hover
    .ant-slider-track
        background-color: #84687f !important
        border-color:#84687f !important
        color:#84687f !important
.ant-slider-handle:focus
    background-color: #84687f
    border-color:#84687f


    
.ant-slider-handle
    border: solid 2px #84687f
.ant-slider-handle
    background-color: #84687f
    border-color:#84687f
.ant-tooltip-open
    background-color: #84687f !important
    border-color:#84687f !important
.ant-slider:hover
    .ant-slider-handle:not(.ant-tooltip-open) 
        background-color: #84687f
        border-color:#84687f

.total-grade
    margin-top:20% !important