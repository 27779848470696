.btn-rounded
    border-radius: 20px
.btn
    display: inline-block
    text-decoration: none !important
    outline: none !important
    padding: 0.5rem 1.5rem 
    box-shadow: 0px 0px 0px !important
    transition: all 250ms
    cursor: pointer
    font-weight: 600
    text-align: center
    user-select: none
.btn-transparent
    background-color: transparent
    border: none
    outline: none !important

.btn-gold-500
    color: white
    background-color: $gold-500
    border: 1px solid $gold-500
    &:hover,&:focus
        color: white
        background-color: darken($gold-500,5%)
    &:active
        color: white
        background-color: darken($gold-500,15%)
.btn-purple-400
    color: $purple-900
    background-color: $purple-400
    border: 1px solid $purple-400
    &:hover,&:focus
        color: $purple-900
        background-color: darken($purple-400,5%)
    &:active
        color: $purple-900
        background-color: darken($purple-400,15%)
.btn-purple-900
    color: white
    background-color: $purple-900
    border: 1px solid $purple-900
    &:hover,&:focus
        color: white
        background-color: darken($purple-900,5%)
    &:active
        color: white
        background-color: darken($purple-900,15%)
    
    
.btn-gold-400-outline
    color: white
    background-color: transparent
    border: 1px solid $gold-400
    &:hover,&:focus
        background-color: rgba($gold-400,0.2)
        color: $gold-400
    &:active
        background-color: rgba($gold-400,0.1)
