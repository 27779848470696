.landing-body
    background-image: url('/images/bg/islamic-background-design.png')
    background-position: center -750px
    background-size: contain
    background-repeat: no-repeat
    .landing-container
        background-image: url('/images/bg/tall-islamic-figures.png')
        background-size: contain
        background-position: 50px 100px
        background-repeat: repeat-y

@media (max-width: 991px)
    .landing-body
        background-position: center -400px
        .landing-container
            background-size: 30% auto
@media (max-width: 767px)
    .landing-body
        background-position: center -200px