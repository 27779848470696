.winners-section
    .winners-imgs-wrapper
        position: relative
        height: 400px
        width: 517px
        margin: 0 auto
        .winner-img-holder
            position: absolute
            overflow: hidden
            border-radius: 50%
            img
                width: 100%
                height: 100%
                object-fit: cover
            &:nth-child(1)
                width: 200px
                height: 200px
                right: 10%
                top: 20%
            &:nth-child(2)
                width: 270px
                height: 270px
                top: 0%
                left: 5%
            &:nth-child(3)
                width: 210px
                height: 210px
                bottom: 5%
                right: 0
            &:nth-child(4)
                width: 220px
                height: 220px
                bottom: 0
                left: 25%
@media (min-width: 1660px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            .winner-img-holder
                &:nth-child(1)
                    width: 230px
                    height: 230px
                    right: 10%
                    top: 4%
                &:nth-child(2)
                    width: 345px
                    height: 345px
                    top: 0%
                    left: -5%
                &:nth-child(3)
                    width: 285px
                    height: 285px
                    bottom: -11%
                    right: -20%
                &:nth-child(4)
                    width: 295px
                    height: 295px
                    bottom: -25%
                    left: 25%    
@media (max-width: 1250px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            .winner-img-holder
                &:nth-child(1)
                    width: 175px
                    height: 175px
                    right: 10%
                    top: 20%
                &:nth-child(2)
                    width: 245px
                    height: 245px
                    top: 0%
                    left: 5%
                &:nth-child(3)
                    width: 185px
                    height: 185px
                    bottom: 5%
                    right: 0
                &:nth-child(4)
                    width: 195px
                    height: 195px
                    bottom: 0
                    left: 25%
@media (max-width: 1090px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            .winner-img-holder
                &:nth-child(1)
                    width: 150px
                    height: 150px
                    right: 10%
                    top: 20%
                &:nth-child(2)
                    width: 220px
                    height: 220px
                    top: 0%
                    left: 5%
                &:nth-child(3)
                    width: 160px
                    height: 160px
                    bottom: 5%
                    right: 0
                &:nth-child(4)
                    width: 170px
                    height: 170px
                    bottom: 0
                    left: 25%
@media (max-width: 991px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            width: 400px
            height: 300px
            margin: 0 auto
            .winner-img-holder
                &:nth-child(1)
                    width: 150px
                    height: 150px
                    right: 10%
                    top: 20%
                &:nth-child(2)
                    width: 220px
                    height: 220px
                    top: 0%
                    left: 5%
                &:nth-child(3)
                    width: 160px
                    height: 160px
                    bottom: 5%
                    right: 0
                &:nth-child(4)
                    width: 170px
                    height: 170px
                    bottom: 0
                    left: 25%
@media (max-width: 450px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            width: 300px
            height: 300px
            margin: 0 auto
            .winner-img-holder
                &:nth-child(1)
                    width: 120px
                    height: 120px
                    right: 10%
                    top: 20%
                &:nth-child(2)
                    width: 190px
                    height: 190px
                    top: 0%
                    left: 5%
                &:nth-child(3)
                    width: 130px
                    height: 130px
                    bottom: 5%
                    right: 0
                &:nth-child(4)
                    width: 140px
                    height: 140px
                    bottom: 0
                    left: 25%
@media (max-width: 370px)
    .winners-section
        .winners-imgs-wrapper
            position: relative
            width: 280px
            height: 250px
            margin: 0 auto
            .winner-img-holder
                &:nth-child(1)
                    width: 100px
                    height: 100px
                    right: 10%
                    top: 20%
                &:nth-child(2)
                    width: 170px
                    height: 170px
                    top: 0%
                    left: 5%
                &:nth-child(3)
                    width: 110px
                    height: 110px
                    bottom: 5%
                    right: 0
                &:nth-child(4)
                    width: 120px
                    height: 120px
                    bottom: 0
                    left: 25%