#navbar
    .navbar-container
        background-color: $purple-900
        padding: 0.5rem 0
        .nav-brand
            .nav-brand-img-wrapper
                width: 80px
                img
                    width: 100%
        .nav-content
            flex-grow: 1
            padding: 0 1rem
            display: flex
            align-items: center
            .nav-list
                flex-grow: 3
                display: flex
                justify-content: center
                .nav-list-item
                    margin: 0 1rem
                    .nav-list-link
                        text-decoration: none
                        color: $gold-400
                        font-weight: 500
                        &:hover
                            color: $gold-500
            .login-btn-wrapper
                margin: 0 0.5rem
                flex-grow: 1
            .lang-wrapper
                margin: 0 1rem
                a
                    text-decoration: none
                    color: $gold-400
                    &:hover
                        color: $gold-500
            .drower-btn-wrapper
                // justify-self: self-end
#mobile-nav
    direction: ltr
    .ant-drawer-content-wrapper
        transition: all 300ms
        opacity: 0
        pointer-events: none
    .ant-drawer-body
        padding: 0
        height: 100%
        .drawer-nav-body
            height: 100%
            background-color: $purple-900
            .nav-list
                padding: 1rem
                .nav-list-item
                    margin: 0.6rem 0
                    padding-bottom: 0.6rem
                    border-bottom: 1px solid $gold-500
                    .nav-list-link
                        text-decoration: none
                        color: $gold-400
                        font-weight: 600
                        display: block
                        &:hover
                            color: $gold-500
            .login-btn-wrapper
                text-align: center

            .lang-wrapper
                text-align: center
                a
                    text-decoration: none
                    color: $gold-400
                    &:hover
                        color: $gold-500

    &.ant-drawer-open
        .ant-drawer-content-wrapper
            opacity: 1
            pointer-events: all
header
    position: fixed
    width: 100%
    z-index: 3
main
    padding-top: 61px
    min-height: 100vmin
