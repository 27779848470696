.privacy-policy-page
    position: relative
    .bg-container
        position: absolute
        width: 100%
        height: 100%
        opacity: 0.2
        background-image: url('/images/bg/tall-islamic-figures.png')
        background-size: 30% auto
        background-position: 90% 100px
        background-repeat: repeat-y

@media (max-width: 991px)
    .privacy-policy-page
        .bg-container
            background-size: 30% auto
.rtl
    .privacy-policy-page
        .bg-container
            background-position: 50px 100px