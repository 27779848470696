.link-card
    text-decoration: none !important
    color: unset !important
.white-card
    background-color: white
    padding: 1rem 1.5rem
    border-radius: 10px
.shadow-card
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

@media (max-width: 600px)
    .white-card
        padding: 0.5rem 1rem
@media (max-width: 420px)
    .white-card
        padding: 0.5rem 0.5rem