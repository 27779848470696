.choose-register-page
    .register-type-wrapper
        display: flex
        .register-choice
            width: 50%
            padding: 1rem
            .register-choice-label
                box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.16)
                background-color: white
                padding: 1rem
                width: 100%
                height: 120px
                color: $purple-450
                border-radius: 5px
                transition: all 300ms
                .img-wrapper
                    padding: 0 0.5rem 0.4rem
                    height: 90%
                    .svg
                        width: 100%
                        height: 100%
                        object-fit: contain
                        object-position: center
            .register-radio
                &:checked
                    &+.register-choice-label
                        background-color: $purple-900
                        color: white
                        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16)
                        .img-wrapper
                            .svg
                                path
                                    fill: white
@media (max-width: 320px)
    .choose-register-page
        .register-type-wrapper
            .register-choice
                .register-choice-label

                    padding: 0.5rem
                    height: 110px
