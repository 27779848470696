.rtl {
    direction: rtl;
    text-align: right;
    font-family: 'DroidArabicKufi', sans-serif;


    .float-right,
    .sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
        float: left;
    }

    .float-left {
        float: right;
    }

    .text-left,
    .sidebar-dark a,
    .collapse-header,
    .dropdown-menu-right,
    table {
        text-align: right !important;
    }

    .text-right {
        text-align: left;
    }

    .sidebar-dark {
        padding: 0;
    }

    .sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
        content: '\f104' !important;
    }

    .sidebar #sidebarToggle::after {
        content: '\f105' !important;
    }

    .sidebar.toggled #sidebarToggle::after {
        content: '\f104' !important;
    }

    .ml-auto {
        margin-left: initial !important;
        margin-right: auto !important;
    }

    .react-tel-input .form-control {
        padding-right: 48px;
    }

    @media (min-width: 768px) {
        .ml-md-auto {
            margin-left: initial !important;
            margin-right: auto !important;
        }
    }

    .mr-auto {
        margin-right: initial !important;
        margin-left: auto !important;
    }

    .dropdown-menu-right {
        right: auto !important;
        left: 0;
    }

    .mr-2 {
        margin-left: .5rem !important;
        margin-right: unset !important;
    }

    .dropdown-menu-right i {
        float: left;
    }

    .pr-0 {
        padding-right: 1rem !important;
        padding-left: 0
    }

    .mr-3 {
        margin-left: 1rem !important;
        margin-right: unset !important;
    }

    .input-group>.input-group-append>.btn,
    .input-group>.input-group-append>.input-group-text,
    .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
    .input-group>.input-group-prepend:not(:first-child)>.btn,
    .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .topbar .navbar-search input {
        border-radius: 0 !important;
    }

    ul {
        padding: 0;
    }

    // p, .card-body p, h1 {
    //     text-align: right;
    // }

    /* custom */

    //ant design
    .ant-pagination {

        .ant-pagination-prev,
        .ant-pagination-next {
            transform: rotate(180deg) !important;
        }
    }

    .ant-select-selection {
        .ant-select-selection__rendered {
            margin-left: 24px;
            margin-right: 11px;

            .ant-select-selection__placeholder {
                text-align: unset;
                text-align: right;
            }

            .ant-select-selection-selected-value {
                float: right;
            }
        }

        .ant-select-arrow {
            left: 11px;
            right: unset;
        }
    }

    .ant-input-search,
    .ant-input-password {
        .ant-input {
            padding-right: 10px;
            padding-left: 30px;
        }

        .ant-input-suffix {
            right: unset;
            left: 12px
        }


    }

    .ant-select-dropdown-menu-item {
        text-align: right !important;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
        float: right;
    }

    .ant-input-number-input {
        text-align: right;
    }

    .ant-notification-notice-icon {
        left: 18px;
    }

    .ant-drawer-content-wrapper {
        direction: rtl;
    }

    //matrial ui date picker
    .MuiPickersCalendarHeader-iconButton {
        transform: rotate(180deg);
    }

    .MuiPickersTimePickerToolbar-ampmSelection {
        margin-left: -20px;
        margin-right: 20px;
    }


    .landing_competitions_section {
        .competition-item {
            direction: rtl;
        }
    }

    .competetions-white-card-section {
        .competiotion-card {
            direction: rtl;
        }
    }

    .profile_container {
        padding-left: 10%;
    }

    .profile_card_icon {
        float: left;
    }

    .competitons_container {
        direction: rtl !important;
        padding-left: 0
    }

    .criteria_name {
        text-align: right;
    }

    .bar-grades {
        padding-left: 0;
        padding-right: 10px;
    }

    .min-grade {
        float: right;
    }

    .max-grade {
        float: left;
    }

    .criteria_section {
        border-right: 2px #c1b3bf solid;
        border-radius: 16px;
        padding-top: 15px;
        box-shadow: -3.5px 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    //phone input
    .react-phone-number-input__row,
    .PhoneInput,
    .react-tel-input {
        .selected-flag {
            .flag {
                left: 8px !important;

                .arrow {
                    left: 10px !important
                }
            }
        }

        input {
            direction: ltr;
            text-align: right;
        }

        #phone-form-control {
            padding-left: 0 !important;
            padding-right: 48px !important;
        }

    }
}