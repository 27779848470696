@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Tajawal:400,500,700&display=swap')
@import './vendors/bootstrap/bootstrap'

//variables
@import 'variables/dir'
// @import 'fonts/dir'
@import 'basic/dir'

//components
@import 'inside-pages/side-bar/_side-bar'
@import 'inside-pages/profile/_profile'
@import 'navbar/dir'
@import 'footer/dir'
//screen
@import 'landing/dir'
@import 'auth/dir'

@import 'privacy-policy/dir'





//rtl
@import 'rtl'
//ltr
@import 'ltr'
